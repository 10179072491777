import React from "react";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import BottomSwipeableDrawer from "./BottomSwipeableDrawer";

export interface ResponsiveDropdownMenuItemProps {
  label: string;
  onClick: () => void;
  divider?: boolean;
  color?: string;
}

interface ResponsiveDropdownMenuProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  menuItems: ResponsiveDropdownMenuItemProps[];
  anchorEl?: HTMLElement | null;
  allwaysShowTitle?: boolean;
}

const ResponsiveDropdownMenu: React.FC<ResponsiveDropdownMenuProps> = ({
  open,
  onClose,
  title,
  menuItems,
  anchorEl,
  allwaysShowTitle = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleItemClick = (action: () => void) => {
    action();
    onClose();
  };

  return (
    <>
      {isMobile ? (
        <BottomSwipeableDrawer
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          title={title}
        >
          <List>
            {menuItems
              .filter((item) => !item.divider)
              .map((item, index) => (
                <ListItem
                  key={index}
                  onClick={() => handleItemClick(item.onClick)}
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: 500,
                      color: item?.color || "text.primary",
                    }}
                  />
                </ListItem>
              ))}
          </List>
        </BottomSwipeableDrawer>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              minWidth: "200px",
              background: (theme) => theme.palette.backgrounds.menu,
            },
          }}
        >
          {allwaysShowTitle && title && (
            <MenuItem
              disabled
              sx={{
                fontWeight: 700,
                fontSize: "12px",
                color: "text.disabled",
              }}
            >
              {title}
            </MenuItem>
          )}
          {menuItems.map((item, index) =>
            item.divider ? (
              <Divider key={index} />
            ) : (
              <MenuItem
                key={index}
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  color: item.color || "text.primary",
                }}
                onClick={() => handleItemClick(item.onClick)}
              >
                {item.label}
              </MenuItem>
            ),
          )}
        </Menu>
      )}
    </>
  );
};

export default ResponsiveDropdownMenu;
