import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Paper,
  // IconButton,
  // InputAdornment,
  TextField,
  Typography,
  // useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  ReportOnTemplate,
  ReportTemplate,
  ReportTemplatePropertyType,
} from "../../models";
import TemplatePropertyIcon from "./TemplatePropertyIcon";
import { useSnackbar } from "../../../../shared/contexts/SnackbarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  addItem,
  updateItem,
  deleteItem,
} from "../../store/slices/reportListSlice";
import { api } from "../../../../api/twelve";
import SearchPlayerModal from "../../shared/search/SearchPlayerModal";
// import MicIcon from "@mui/icons-material/Mic";
import { useCurrentUser } from "../../../auth/contexts/CurrentUserContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ResponsiveDropdownMenu, {
  ResponsiveDropdownMenuItemProps,
} from "../../../../shared/components/ResponsiveDropdownMenu";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import { UserPermissions } from "../../../auth/models";
import SearchTeamModal from "../../shared/search/SearchTeamModal";
import SearchMatchModal from "../../shared/search/SearchMatchModal";
import AddIcon from "@mui/icons-material/Add";
import StringHelper from "../../../../shared/helpers/string.helper";
import MentionsTextField from "../../shared/MentionsTextField/MentionsTextField";

export default function ReportForm({
  variant = "create",
}: {
  variant: "create" | "update";
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { currentUser } = useCurrentUser();
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState<
    Array<ResponsiveDropdownMenuItemProps>
  >([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [namePlaceholder, setNamePlaceholder] = useState<string>("");
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [isErrorPropertiesDialogOpen, setIsErrorPropertiesDialogOpen] =
    useState(false);

  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setMenuAnchorEl(null);
  };

  const [template, setTemplate] = useState<ReportOnTemplate>({
    id: 0,
    template_id: 0,
    name: "",
    description: "",
    content: { sections: [], properties: [] },
  });

  const [errors, setErrors] = useState({
    name: false,
    properties: false,
    sections: [
      {
        questions: [false],
      },
    ],
  });

  useEffect(() => {
    if (!location.state?.report) return;

    const setReportOnTemplate = (template: ReportOnTemplate): void => {
      setErrors({
        name: false,
        properties: false,
        sections: template.content.sections.map(() => ({
          questions: template.content.sections.map(() => false),
        })),
      });
      setTemplate(structuredClone(template));
    };

    if (variant === "create") {
      setNamePlaceholder(location.state.report?.name);
      const reportTemplate = location.state.report as ReportTemplate;
      const template = {
        id: 0,
        name: "",
        description: reportTemplate.description,
        template_id: reportTemplate.id,
        content: reportTemplate.structure,
      };
      setReportOnTemplate(template);
    } else {
      setNamePlaceholder("Report name");
      const fetchTemplate = async () => {
        const template = await api.getReportOnTemplateById(
          await getAccessTokenSilently(),
          location.state?.report.id,
          currentUser?.chekPermissions(
            UserPermissions.READ_ALL_SCOUT_REPORTS,
          ) && currentUser.id !== location.state?.report.user_id,
        );
        setReportOnTemplate(template);
      };
      fetchTemplate();
    }
  }, [variant, location, getAccessTokenSilently, currentUser]);

  useEffect(() => {
    if (variant === "create") {
      setCanUpdate(true);
      setMenuItems([]);
    } else {
      if (currentUser) {
        const items = [
          {
            label: "Duplicate",
            onClick: async () => {
              try {
                const report = { ...template };
                report.name = template?.name ? `${template?.name} (copy)` : "";
                report.template_name = template?.template_name;
                const duplicate = await api.addReportOnTemplate(
                  await getAccessTokenSilently(),
                  report,
                );
                duplicate.template_name = template?.template_name;
                dispatch(addItem(duplicate));
                navigate("/report");
                showSnackbar(`Report was duplicated`);
              } catch (error: any) {
                showSnackbar(`${error.message}`, {}, "error");
              }
            },
          },
        ];
        if (currentUser.id === template.user_id) {
          items.push({ divider: true } as any);
          items.push({
            label: "Delete",
            onClick: () => {
              handleOpenDeleteDialog();
            },
            color: "warning.main",
          } as any);
          setCanUpdate(true);
          setMenuItems(items);
        } else {
          setCanUpdate(false);
          setMenuItems(items);
        }
      }
    }
  }, [
    template,
    currentUser,
    variant,
    dispatch,
    getAccessTokenSilently,
    navigate,
    showSnackbar,
  ]);

  const [open, setOpen] = useState<ReportTemplatePropertyType | undefined>(
    undefined,
  );

  const handleOpen = (type: ReportTemplatePropertyType | undefined) => {
    setOpen(type);
  };

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleSelectProperty = (
    propertyType: ReportTemplatePropertyType,
    data: Array<{ id: number; value: string }>,
  ) => {
    const newTemplate = {
      ...template,
      content: {
        ...template.content,
        properties: template.content.properties.map((property) =>
          property.type === propertyType
            ? {
                ...property,
                data: [...(property.data || []), ...data],
              }
            : property,
        ),
      },
    };
    setErrors((prevErrors) => ({
      ...prevErrors,
      properties: false,
    }));
    setTemplate(newTemplate);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      name: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: template.content.properties?.length === 0 && value.trim() === "",
    }));
  };

  const handleAnswerChange = (
    sectionIndex: number,
    questionIndex: number,
    value: string,
    localProperties: Array<{ id: number; value: string }> = [],
  ) => {
    setTemplate((prevTemplate) => {
      const newSections = prevTemplate.content.sections.map(
        (section, sIndex) => {
          if (sIndex === sectionIndex) {
            return {
              ...section,
              questions: section.questions.map((question, qIndex) => {
                if (qIndex === questionIndex) {
                  return {
                    ...question,
                    answer: value,
                  };
                }
                return question;
              }),
            };
          }
          return section;
        },
      );

      if (localProperties.length) {
        const playerProperties = prevTemplate.content.properties.find(
          (p) => p.type === "player",
        );
        if (playerProperties) {
          if (!playerProperties.data) {
            playerProperties.data = [];
          }
          const ids = new Set(playerProperties.data.map((p) => p.id));
          playerProperties.data = [
            ...playerProperties.data,
            ...localProperties.filter((p) => !ids.has(p.id)),
          ];
        }
      }

      return {
        ...prevTemplate,
        content: {
          ...prevTemplate.content,
          sections: newSections,
        },
      };
    });
    setErrors((prevErrors) => {
      const newSectionErrors = [...prevErrors.sections];
      newSectionErrors[sectionIndex].questions[questionIndex] =
        value.trim() === "";
      return {
        ...prevErrors,
        sections: newSectionErrors,
      };
    });
  };

  const handleDuplicateQuestion = (
    sectionIndex: number,
    questionIndex: number,
  ) => {
    setTemplate((prevTemplate) => {
      const question =
        prevTemplate.content.sections[sectionIndex].questions[questionIndex];
      prevTemplate.content.sections[sectionIndex].questions[
        questionIndex
      ].allowDuplicate = false;

      const newSections = prevTemplate.content.sections.map(
        (section, sIndex) => {
          if (sIndex === sectionIndex) {
            return {
              ...section,
              questions: [
                ...section.questions.slice(0, questionIndex + 1),
                {
                  question: question.question,
                  answer: "",
                  allowDuplicate: true,
                },
                ...section.questions.slice(questionIndex + 1),
              ],
            };
          }
          return section;
        },
      );

      return {
        ...prevTemplate,
        content: {
          ...prevTemplate.content,
          sections: newSections,
        },
      };
    });
  };

  const handleSubmit = async (validate: boolean = true) => {
    let hasNoErrors = true;
    if (validate) {
      const nameError =
        template.content.properties?.length === 0 &&
        template.name.trim() === "";
      const sectionErrors = template.content.sections.map((section) => ({
        questions: section.questions.map(
          (subquestion) =>
            // !subquestion.answer || subquestion.answer?.trim() === "",
            false,
        ),
      }));
      const propertiesError = template.content.properties.some(
        (p) => !p.data?.length,
      );

      if (propertiesError) {
        setIsErrorPropertiesDialogOpen(true);
        return;
      }

      setErrors({
        name: nameError,
        properties: false,
        sections: sectionErrors,
      });

      hasNoErrors =
        !nameError &&
        //!propertiesError &&
        !sectionErrors.some((section) =>
          section.questions.some((error) => error),
        );
    }

    if (hasNoErrors) {
      try {
        if (variant === "create") {
          dispatch(
            addItem(
              await api.addReportOnTemplate(
                await getAccessTokenSilently(),
                template,
              ),
            ),
          );
        } else {
          dispatch(
            updateItem(
              await api.updateReportOnTemplate(
                await getAccessTokenSilently(),
                template,
              ),
            ),
          );
        }
      } catch (error: any) {
        showSnackbar(`${error.message}`, {}, "error");
        return;
      }
      showSnackbar(`Report ${template.name} was saved`);
      navigate("/report");
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <Box
      className="scrollable"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: "16px",
        overflow: "auto",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
          maxWidth: "640px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              pb: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Button
              variant="text"
              size="medium"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={!canUpdate}
            >
              Save
            </Button>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => navigate("/report")}
            >
              Cancel
            </Button>
          </Box>
          {variant === "update" && (
            <>
              <IconButton onClick={handleMenuButtonClick}>
                <MoreVertIcon />
              </IconButton>
              <ResponsiveDropdownMenu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                title={template.name}
                menuItems={menuItems}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            padding: " 0 16px 16px 16px",
          }}
        >
          <Box>
            <TextField
              variant="standard"
              fullWidth
              required
              placeholder={namePlaceholder}
              value={template.name ?? ""}
              onChange={handleNameChange}
              error={errors.name}
              helperText={errors.name ? "This field is required" : ""}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontSize: "24px",
                  fontWeight: 700,
                  "& .MuiInputBase-root": {
                    borderBottom: "none",
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              marginTop: "-12px",
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                width: descriptionExpanded ? 0 : "60px",
                background: `linear-gradient(90deg, rgba(245, 245, 245, 0.00) 0%, ${theme.palette.background.default} 87.5%)`,
              },
            }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                fontSize: "12px",
                textOverflow: "ellipsis",
                overflow: descriptionExpanded ? "unset" : "hidden",
                whiteSpace: descriptionExpanded ? "unset" : "nowrap",
                cursor: "pointer",
              }}
              onClick={() => setDescriptionExpanded(!descriptionExpanded)}
            >
              {template.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {template.content.properties.map((property, index) => (
                <Box
                  key={`property-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "12px",
                    color: (theme) => theme.palette.text.secondary,
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    <Box>
                      <Box
                        className="icon"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "2px 4px 0 4px",
                          boxSizing: "border-box",
                        }}
                      >
                        <TemplatePropertyIcon property={property} />
                      </Box>
                    </Box>
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      disabled={property.type === "tags" || !canUpdate}
                      onClick={() => handleOpen(property.type)}
                      endIcon={<AddIcon />}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {`Add ${property.type}`}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      [theme.breakpoints.down("sm")]: {
                        flexDirection: "column",
                        gap: "8px",
                      },
                    }}
                  >
                    {property.data?.map((data, dataIndex) => (
                      <Chip
                        key={`chip-${dataIndex}`}
                        label={data.value}
                        disabled={!canUpdate}
                        variant="outlined"
                        onDelete={() => {
                          const newTemplate = {
                            ...template,
                            content: {
                              ...template.content,
                              properties: template.content.properties.map((p) =>
                                p.type === property.type
                                  ? {
                                      ...p,
                                      data: p.data?.filter(
                                        (d) => d.value !== data.value,
                                      ),
                                    }
                                  : p,
                              ),
                            },
                          };
                          setTemplate(newTemplate);
                        }}
                        sx={{
                          "&.Mui-disabled": {
                            opacity: 1,
                            "& .MuiChip-deleteIcon": {
                              display: "none",
                            },
                          },
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            <SearchPlayerModal
              open={open === "player"}
              onClose={handleClose}
              onSelect={(player) =>
                handleSelectProperty("player", [
                  {
                    id: player.id,
                    value: player.name,
                  },
                ])
              }
            />
            <SearchTeamModal
              open={open === "team"}
              onClose={handleClose}
              onSelect={(team) =>
                handleSelectProperty("team", [
                  {
                    id: team.team_id,
                    value: team.team,
                  },
                ])
              }
            />
            <SearchMatchModal
              open={open === "match"}
              onClose={handleClose}
              onSelect={(match) => {
                handleSelectProperty("match", [
                  {
                    id: match.match_id,
                    value: match.label,
                  },
                ]);
              }}
            />
            {errors.properties && (
              <Typography color="error" sx={{ fontSize: "0.75rem" }}>
                Please select value for all properties
              </Typography>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {template.content.sections.map((section, sectionIndex) => (
                <React.Fragment key={`section-${sectionIndex}`}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      color: "text.secondary",
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: "16px",
                      padding: "16px",
                      zIndex: 0,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "text.secondary",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {section.header}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      {section.questions.map((question, questionIndex) => (
                        <React.Fragment key={`question-${questionIndex}`}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              gap: "8px",
                              width: "100%",
                            }}
                          >
                            <MentionsTextField
                              value={question.answer || ""}
                              variant={"standard"}
                              fullWidth
                              multiline
                              disabled={!canUpdate}
                              placeholder="Answer..."
                              InputLabelProps={{
                                shrink: false,
                              }}
                              sx={{
                                "& .Mui-disabled": {
                                  color: `${theme.palette.text.primary}`,
                                  WebkitTextFillColor: `${theme.palette.text.primary}`,
                                },
                              }}
                              onChange={(
                                newValue: string,
                                newPlainText: string,
                                mentions: any[],
                              ) => {
                                handleAnswerChange(
                                  sectionIndex,
                                  questionIndex,
                                  newValue,
                                  mentions.map((m) => ({
                                    id: Number(m.id),
                                    value: String(m.display)
                                      .replaceAll("@", "")
                                      .trim(),
                                  })),
                                );
                              }}
                              helperText={question.question}
                              // error={
                              //   errors.sections[sectionIndex].questions[
                              //     questionIndex
                              //   ]
                              // }
                              // helperText={
                              //   errors.sections[sectionIndex].questions[
                              //     questionIndex
                              //   ]
                              //     ? "This field is required"
                              //     : question.question
                              // }
                              taggedPlayers={
                                template.content.properties.find(
                                  (p) => p.type === "player",
                                )?.data
                              }
                            />
                            {question.allowDuplicate && canUpdate ? (
                              <Button
                                variant="text"
                                color="inherit"
                                size="small"
                                startIcon={<AddIcon />}
                                disabled={!canUpdate}
                                onClick={() =>
                                  handleDuplicateQuestion(
                                    sectionIndex,
                                    questionIndex,
                                  )
                                }
                              >
                                Duplicate question
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                          {questionIndex !== section.questions.length - 1 && (
                            <Divider />
                          )}
                        </React.Fragment>
                      ))}
                    </Box>
                  </Box>
                  {sectionIndex !== template.content.sections.length - 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
      <ResponsiveDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        title="Delete report"
        actions={
          <>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => {
                handleCloseDeleteDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="warning"
              onClick={async () => {
                if (!template.id) return;
                try {
                  await api.deleteReportOnTemplate(
                    await getAccessTokenSilently(),
                    template.id,
                  );

                  dispatch(deleteItem(template.id));
                  navigate("/report");
                  showSnackbar(
                    `Report ${template.name} was deleted`,
                    { icon: <></>, textColor: theme.palette.error.main },
                    "warning",
                  );
                } catch (error: any) {
                  showSnackbar(`${error.message}`, {}, "error");
                }
                handleCloseDeleteDialog();
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Typography variant="body1" color={"text.secondary"}>
          This action can’t be undone.
        </Typography>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isErrorPropertiesDialogOpen}
        onClose={() => setIsErrorPropertiesDialogOpen(false)}
        title="Unfilled properties"
        actions={
          <>
            <Button
              variant="text"
              size="medium"
              color="secondary"
              onClick={() => {
                setIsErrorPropertiesDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={async () => {
                await handleSubmit(false);
                setIsErrorPropertiesDialogOpen(false);
              }}
            >
              Save anyway
            </Button>
          </>
        }
      >
        <Typography variant="body1" color={"text.secondary"}>
          You haven’t filled in properties:
        </Typography>
        <ul style={{ paddingLeft: "20px" }}>
          {template.content.properties
            .filter((property) => !property.data?.length)
            .map((property, index) => (
              <li key={`properties-error-${index}`}>
                <Typography variant="body1" color={"text.secondary"}>
                  {StringHelper.normalizeKey(property.type)}
                </Typography>
              </li>
            ))}
        </ul>
      </ResponsiveDialog>
    </Box>
  );
}
