import { Box } from "@mui/material";
import PlayerScoutingReport from "./scouting/ScoutingReport";
import { useEffect, useState, useRef } from "react";
import { api } from "../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { ReportContent, ReportPage as ReportPageModel } from "./models";
import LoadingScreen from "./shared/LoadingScreen";

export default function ReportPage({
  isPublic = false,
}: {
  isPublic?: boolean;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportContent, setReportContent] = useState<ReportContent | undefined>(
    undefined,
  );
  const { reportId } = useParams();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const getReportPage = async (page: number) => {
      if (page === 0) {
        setLoading(true);
      }

      try {
        // Fetch the page data
        let data: ReportContent;
        if (isPublic) {
          data = await api.getReportByPublicId(String(reportId), page, 1);
        } else {
          const token = await getAccessTokenSilently();
          if (!abortControllerRef.current) return;
          data = await api.getReportContentById(
            token,
            Number(reportId),
            abortControllerRef.current.signal,
            page,
            1,
          );
        }

        if (page === 0) {
          setLoading(false);
          // We need to add the loading sceleton for all pages except the first one
          if (data.content?.total_pages && data.content.total_pages > 1) {
            const totalPages = data.content!.total_pages;
            const loadingSceletonPages = Array.from(
              { length: totalPages - 1 },
              (_, page) =>
                ({
                  index: page + 1,
                  type:
                    page === totalPages - 2
                      ? "glossary_pages"
                      : "standard_page",
                  title: "Title",
                  subtitle: "Subtitle",
                  main_header: "Main header",
                  header:
                    page === 0
                      ? "Overview"
                      : data.content?.table_of_content![page - 1],
                  subheader: data.content?.pages![0].title,
                  slides: [
                    {
                      type: "standard_content",
                      header: "Header",
                      description: "Description",
                    },
                  ] as any,
                  loading: true,
                } as ReportPageModel),
            );

            data.content.pages = [
              ...data.content.pages!,
              ...loadingSceletonPages,
            ].sort((a, b) => a.index - b.index);

            setReportContent(data);
          }
        } else {
          // Update the page with the fetched data
          setReportContent((prev) => {
            if (!prev) return data;
            if (prev?.content?.pages && data.content?.pages) {
              return {
                ...prev,
                content: {
                  ...prev.content,
                  pages: [
                    ...prev.content.pages?.filter((p) => p.index !== page),
                    ...data.content?.pages!,
                  ].sort((a, b) => a.index - b.index),
                },
              };
            }
            return prev;
          });
        }

        if (page < 2 && page + 1 < data.content?.total_pages!) {
          setCurrentPage(page + 1);
        }
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      }
    };

    getReportPage(currentPage);
  }, [isPublic, reportId, getAccessTokenSilently, currentPage]);

  useEffect(() => {
    if (!isPublic) abortControllerRef.current = new AbortController();
    setReportContent(undefined);
    setCurrentPage(0);

    if (!isPublic) {
      return () => {
        if (abortControllerRef.current) abortControllerRef.current.abort();
      };
    }
  }, [isPublic, reportId]);

  const renderReport = () => {
    switch (reportContent?.report_type) {
      case "SCOUT_REPORT":
        return (
          <PlayerScoutingReport
            content={reportContent.content!}
            tableOfContent={true}
            onPageLoad={(pageNumber) => {
              if (pageNumber < reportContent?.content?.total_pages! - 1) {
                setCurrentPage(pageNumber + 1);
              }
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.customColors.report.background,
        overflow: "auto",
        height: "100%",
      }}
    >
      {loading ? (
        <LoadingScreen useCounter={true} useTypingEffect={false} />
      ) : (
        renderReport()
      )}
    </Box>
  );
}
