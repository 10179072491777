import React, { ComponentType, useEffect, useMemo, useState } from "react";
import {
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
  Navigate,
} from "react-router-dom";
import styles from "./App.module.css";
import { Box, GlobalStyles, useMediaQuery } from "@mui/material";
import {
  createCustomTheme,
  PaletteModeContext,
  ThemeMode,
  getGlobalStyles,
} from "./themes/theme";
import ConversationPage from "./sections/conversation/ConversationPage";
import {
  Auth0Provider,
  withAuthenticationRequired,
  useAuth0,
} from "@auth0/auth0-react";
import SignInPage from "./sections/auth/SignInPage";
import TopBar from "./layout/TopBar";
import Sidebar from "./layout/Sidebar";
import { Provider, useDispatch } from "react-redux";
import store from "./store";
import ConversationStartingScreen from "./sections/conversation/ConversationStartingScreen";
import PageLoader from "./shared/components/PageLoader";
import ReportPage from "./sections/reports/ReportPage";
import ReportsPage from "./sections/reports/ReportsPage";
import { config } from "./config";
import { ThemeProvider } from "@mui/material/styles";
import { setSection } from "./store/viewReducer";
import ReportTemplateForm from "./sections/reports/templates/components/ReportTemplateForm";
import { SnackbarProvider } from "./shared/contexts/SnackbarContext";
import ReportForm from "./sections/reports/templates/components/ReportForm";
import SavedReportsPage from "./sections/reports/saved/SavedReportsPage";
import {
  useCurrentUser,
  CurrentUserProvider,
} from "./sections/auth/contexts/CurrentUserContext";
import { UserPermissions } from "./sections/auth/models";
import ForbiddenPage from "./pages/ForbiddenPage";
import ChartPage from "./pages/ChartPage";

function MainApp() {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.startsWith("/report")) {
      dispatch(setSection("reports"));
    } else {
      dispatch(setSection("conversation"));
    }
  }, [location, dispatch]);

  const MainComponent = () => {
    const { currentUser } = useCurrentUser();

    if (!currentUser) return <PageLoader variant="full" />;

    return (
      <React.Fragment>
        <TopBar />
        <Box className={styles.main}>
          <Box>
            <Sidebar />
          </Box>
          <Box
            className={styles.page}
            sx={{
              backgroundColor: "background.default",
            }}
          >
            {location.pathname === "/" ? (
              <ConversationStartingScreen />
            ) : (
              <Outlet />
            )}
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  if (!isAuthenticated) {
    return <SignInPage />;
  } else {
    if (isLoading) {
      return <PageLoader variant="full" />;
    } else {
      return (
        <CurrentUserProvider>
          <MainComponent />
        </CurrentUserProvider>
      );
    }
  }
}

function PublicApp() {
  return (
    <>
      <Box className={styles.main}>
        <Box
          className={styles.page}
          sx={{
            marginTop: 0,
            height: "100%",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

interface ProtectedComponentProps {
  component: ComponentType<any>;
  [key: string]: any;
  permissions?: Array<UserPermissions> | UserPermissions;
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component,
  permissions,
  ...args
}) => {
  const { currentUser } = useCurrentUser();
  const Component = withAuthenticationRequired(component, args);

  if (permissions?.length) {
    if (!currentUser?.chekPermissions(permissions)) {
      return <Navigate to="/forbidden" />;
    }
  }

  return <Component />;
};

function MainRouter() {
  const { isAuthenticated } = useAuth0();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const defaultThemeMode = "dark";

  useEffect(() => {
    if (!isAuthenticated) {
      setThemeMode("light");
    } else {
      setThemeMode(
        (localStorage.getItem("themeMode") as ThemeMode) || defaultThemeMode,
      );
    }
  }, [isAuthenticated]);

  const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
    return (localStorage.getItem("themeMode") as ThemeMode) || defaultThemeMode;
  });

  const paletteMode = React.useMemo(
    () => ({
      mode: themeMode,
      setMode: (mode: ThemeMode) => {
        setThemeMode(mode);
        localStorage.setItem("themeMode", mode);
      },
    }),
    [themeMode],
  );

  const theme = useMemo(
    () => createCustomTheme(themeMode, prefersDarkMode),
    [themeMode, prefersDarkMode],
  );
  const globalStyles = useMemo(() => getGlobalStyles(theme), [theme]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* private routes */}
        <Route path="/" element={<MainApp />}>
          <Route
            path="/conversation/:conversationId"
            element={<ProtectedComponent component={ConversationPage} />}
          />
          <Route path="saved-reports">
            <Route
              path=""
              element={<ProtectedComponent component={SavedReportsPage} />}
            />
            <Route
              path=":reportId"
              element={<ProtectedComponent component={ReportPage} />}
            />
          </Route>
          <Route path="report">
            <Route
              path=""
              element={
                <ProtectedComponent
                  permissions={[
                    UserPermissions.READ_OWN_SCOUT_REPORTS,
                    UserPermissions.READ_ALL_SCOUT_REPORTS,
                  ]}
                  component={ReportsPage}
                />
              }
            />
            <Route
              path=":reportId"
              element={
                <ProtectedComponent
                  permissions={[
                    UserPermissions.READ_OWN_SCOUT_REPORTS,
                    UserPermissions.READ_ALL_SCOUT_REPORTS,
                  ]}
                  component={ReportPage}
                />
              }
            />
            <Route
              path="create"
              element={
                <ProtectedComponent
                  permissions={[UserPermissions.CRAETE_SCOUT_REPORT]}
                  component={ReportForm}
                />
              }
            />
            <Route
              path=":reportId/update"
              element={
                <ProtectedComponent
                  permissions={[UserPermissions.CRAETE_SCOUT_REPORT]}
                  component={(props) => (
                    <ReportForm {...props} variant="update" />
                  )}
                />
              }
            />
            <Route path="templates">
              <Route
                path="create"
                element={
                  <ProtectedComponent
                    permissions={[UserPermissions.CREATE_SCOUT_TEMPLATE]}
                    component={ReportTemplateForm}
                  />
                }
              />
              <Route
                path=":templateId"
                element={
                  <ProtectedComponent
                    permissions={[UserPermissions.CREATE_SCOUT_TEMPLATE]}
                    component={(props) => (
                      <ReportTemplateForm {...props} variant="update" />
                    )}
                  />
                }
              />
            </Route>
          </Route>
        </Route>
        {/* public routes */}
        <Route path="/" element={<PublicApp />}>
          <Route
            path="/reports/:reportId"
            element={<ReportPage isPublic={true} />}
          />
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="/charts" element={<ChartPage />} />
        </Route>
      </Route>,
    ),
  );

  return (
    <PaletteModeContext.Provider value={paletteMode}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={globalStyles} />
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </PaletteModeContext.Provider>
  );
}

function App() {
  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Provider store={store}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.auth0.audience,
        }}
      >
        <MainRouter />
      </Auth0Provider>
    </Provider>
  );
}

export default App;
